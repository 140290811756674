<ng-container *ngIf="isMobile">
  <vip-pagamento-carteira-digital
    class="vip-page-content"
    [loading]="!!(loadingCompraEmProcesso$ | async)"
    [compraEmProcesso]="(compraEmProcesso$ | async) || null"
    [taxaServico]="taxaServico || null"
    [descontos]="compraEmProcesso?.compra_descontos || []"
    [valorDescontos]="compraEmProcesso?.valor_descontos || 0"
    [currentDate]="currentDate$ | async"
    (tempoExpirado)="onTempoExpirado()"
    (clickedCancelarPagamento)="handleCancelarPagamentoDigital($event)"
    (clickedTentarNovamente)="handleTentarNovamente()"
    (clickedDeeplink)="handleDeeplink($event)"
    (backButtonClicked)="handleGoBack($event)"
    (timerIsRunning)="pagamentoService.setTimerIsRunning($event)"
  ></vip-pagamento-carteira-digital>
</ng-container>

<ng-container *ngIf="!isMobile">
  <vip-stepper-desktop
    [logoSrc]="(logo$ | async) || ''"
    [url_google]="(filial$ | async)?.site_url || '' | getURLGoogleSafe"
    [fallbackImageUrl]="(logoFallback$ | async) || ''"
    [steps]="steps"
    [colorBackground]="true"
  ></vip-stepper-desktop>

  <div class="flex mt-[7.1875rem] mb-4">
    <vip-card-basico
      ngClass="block rounded shadow ml-12 mr-4"
      [disableRipple]="true"
      [expanded]="true"
      [title]="
        (compraEmProcessoLoading$ | async) || loadingHolder
          ? 'Carregando...'
          : 'Pagamento via ' + compraPagamento?.forma_pagamento?.descricao
      "
      padding="desktop"
      [useSubPadding]="true"
      [showHeaderDivider]="true"
    >
      <vip-image
        *ngIf="compraPagamento"
        class="mr-1 max-h-[1.875rem] max-w-[2rem]"
        [alt]="compraPagamento.forma_pagamento.descricao"
        [imageUrl]="compraPagamento.forma_pagamento.logo"
        titleImage
      ></vip-image>
      @if ((compraEmProcessoLoading$ | async) || loadingHolder) {
        <div data-cy="vip-loading-wrapper" class="flex flex-col items-center">
          <ng-container
            *vipSkeleton="
              true;
              height: '38px';
              radius: '5px';
              className: ['max-w-[780px]', 'mb-10', 'mt-4', 'flex-1', 'w-full']
            "
          ></ng-container>
          <ng-container
            *vipSkeleton="
              true;
              width: '173px';
              height: '24px';
              radius: '5px';
              className: ['max-w-[780px]', 'mb-6']
            "
          ></ng-container>
          <ng-container
            *vipSkeleton="
              true;
              width: '180px';
              height: '180px';
              radius: '5px';
              className: ['mb-4']
            "
          ></ng-container>
          <ng-container
            *vipSkeleton="
              true;
              width: '328px';
              height: '28px';
              radius: '5px';
              className: ['mb-10']
            "
          ></ng-container>
          <div class="flex w-full justify-end items-end gap-4">
            <ng-container
              *vipSkeleton="true; width: '235px'; height: '58px'; radius: '5px'"
            ></ng-container>
            <ng-container
              *vipSkeleton="true; width: '158px'; height: '58px'; radius: '5px'"
            ></ng-container>
          </div>
        </div>
      } @else {
        <vip-pagamento-carteira-digital-desktop
          class="vip-page-content"
          [loading]="!!(loadingCompraEmProcesso$ | async)"
          [compraEmProcesso]="(compraEmProcesso$ | async) || null"
          [taxaServico]="taxaServico || null"
          [descontos]="compraEmProcesso?.compra_descontos || []"
          [valorDescontos]="compraEmProcesso?.valor_descontos || 0"
          [currentDate]="currentDate$ | async"
          (clickedCancelarPagamento)="handleCancelarPagamentoDigital($event)"
          (clickedTentarNovamente)="handleTentarNovamente()"
          (clickedDeeplink)="handleDeeplink($event)"
          (timerIsRunning)="pagamentoService.setTimerIsRunning($event)"
          (tempoExpirado)="onTempoExpirado()"
        ></vip-pagamento-carteira-digital-desktop>
      }
    </vip-card-basico>

    <vip-card-detalhes-cobranca
      class="w-full max-w-[20rem] mr-4 display-stick"
      confirmButtonText="Concluir Compra"
      data-cy="vip-detalhes-cobranca"
      [isDesktop]="true"
      [saldoCashback]="undefined"
      [showCashback]="false"
      [disablePagamentoButton]="false"
      [isLoading]="false"
      [showContinuarComprando]="false"
      [valorCompra]="compraEmProcesso?.valor_inicial || 0"
      [totalCompra]="(totalCompra$ | async) || 0"
      [taxaEntrega]="(taxaEntrega$ | async) || 0"
      [descontos]="compraEmProcesso?.compra_descontos || []"
      [valorDescontos]="compraEmProcesso?.valor_descontos || 0"
      [taxaServico]="taxaServico || null"
      [cardBasicBorder]="false"
      [editable]="true"
      [hideConfirmButton]="true"
    ></vip-card-detalhes-cobranca>
  </div>
</ng-container>
