import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { DescontoFacade } from '@vip/state/desconto';
import { FormaPagamentosFacade } from '@vip/state/forma-pagamento';
import { PagamentoService } from '../services/pagamentos.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import {
  ICartao,
  ICompra,
  IFormaPagamento,
  IPagamentosViewModel,
  LayoutUtilsService,
  PagamentoUtilsService,
} from '@vip/core';
import { CartaoFacade } from '@vip/state/cartao';
import { FilialFacade } from '@vip/state/filial';
import { AnalyticsFacade } from '@vip/state/analytics';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { Observable } from 'rxjs';
import { mergeTakeOne } from '@vip/state/utils';
import { LayoutStore } from '@vip/state/layout';

@UntilDestroy()
@Component({
  selector: 'vip-forma-pagamento-container',
  templateUrl: './forma-pagamento-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FormaPagamentoContainerComponent implements OnInit {
  formaPagamentos$!: Observable<IFormaPagamento[]>;
  removerPrePagos$!: Observable<boolean>;
  formaPagamentosSelecionadas$!: Observable<IFormaPagamento>;
  compraEmProcesso$!: Observable<ICompra>;
  hasPortadorValido$!: Observable<boolean>;
  cartoes$!: Observable<ICartao[]>;
  permitirSalvarCartao$!: Observable<boolean>;
  cartaoSelecionadoId$!: Observable<string>;
  isLojaAutonoma$!: Observable<boolean>;
  pagamentos$!: Observable<IPagamentosViewModel | null>;
  isDesktopResponsive = this.layoutUtilsService.isDesktopResponsive();

  constructor(
    public formaPagamentosFacade: FormaPagamentosFacade,
    public compraEmProcessoFacade: CompraEmProcessoFacade,
    private descontoFacade: DescontoFacade,
    private pagamentoService: PagamentoService,
    public cartaoFacade: CartaoFacade,
    private filialFacade: FilialFacade,
    private cdFacade: CentroDistribuicaoFacade,
    private analyticsFacade: AnalyticsFacade,
    private layoutUtilsService: LayoutUtilsService,
    private layoutStore: LayoutStore,
    private pagamentoUtilsService: PagamentoUtilsService,
  ) {
    this.formaPagamentosFacade.getFormaPagamentos();
    this.cartaoFacade.getCartoes();
  }

  ngOnInit(): void {
    this.formaPagamentos$ = this.formaPagamentosFacade.formaPagamentos$;
    this.removerPrePagos$ = this.formaPagamentosFacade.removerPrePagos$;
    this.formaPagamentosSelecionadas$ =
      this.formaPagamentosFacade.formaPagamentosSelecionadas$;
    this.compraEmProcesso$ = this.compraEmProcessoFacade
      .compraEmProcesso$ as Observable<ICompra>;
    this.hasPortadorValido$ = this.compraEmProcessoFacade.hasPortadorValido$;
    this.cartoes$ = this.cartaoFacade.cartoes$;
    this.permitirSalvarCartao$ = this.filialFacade.permitirSalvarCartao$;
    this.cartaoSelecionadoId$ = this.cartaoFacade
      .cartaoSelecionadoId$ as Observable<string>;
    this.isLojaAutonoma$ = this.cdFacade.isLojaAutonoma$ as Observable<boolean>;
    this.pagamentos$ = this.formaPagamentosFacade.pagamentos$;
    if (this.isDesktopResponsive) this.layoutStore.setHeaderVisibility(false);
    this.pagamentoUtilsService.cameFromFormaPagamento.set(true);
  }

  selecionaFormaPagamento(formaPagamentoId?: number) {
    if (formaPagamentoId) {
      this.formaPagamentosFacade.selectFormaPagamento(formaPagamentoId);
    } else {
      this.formaPagamentosFacade.resetFormaPagamentoSelecionada();
    }
    this.pagamentoService.limparValorAcrescimos();
    this.getDescontosCompraEmProcesso();
  }

  getDescontosCompraEmProcesso() {
    this.compraEmProcesso$
      .pipe(
        filter(
          (compraEmProcesso): compraEmProcesso is ICompra =>
            compraEmProcesso !== undefined && compraEmProcesso !== null,
        ),
        take(1),
        untilDestroyed(this),
      )
      .subscribe((compraEmProcesso) =>
        this.descontoFacade.getDescontosCompra(compraEmProcesso.id),
      );
  }

  onConfirm() {
    this.compraEmProcesso$
      .pipe(
        filter((compra): compra is ICompra => !!compra),
        take(1),
        untilDestroyed(this),
        mergeTakeOne(this.formaPagamentosSelecionadas$),
      )
      .subscribe(([compra, formaPagamentoSelecionada]) => {
        this.analyticsFacade.addPagamentoInfo(
          formaPagamentoSelecionada.id,
          compra,
        );
      });
  }
}
