/* eslint-disable rxjs-angular/prefer-takeuntil */
import { CarrinhoFacade } from '@vip/state/carrinho';
import { ValidarPrazoEntregaContainerComponent } from '@vip/container/validar-prazo-entrega';
import { TelevendasFacade } from '@vip/state/televendas';
import { ModalTrocoService } from '@vip/ui/card-pagamento';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnInit,
  Optional,
  signal,
  ViewChild,
} from '@angular/core';
import {
  FacebookPixelService,
  IS_APP,
  PagamentoUtilsService,
  TelevendasTokenService,
} from '@vip/core';
import { FormaPagamentosFacade } from '@vip/state/forma-pagamento';
import { OpcaoFaturamentoFacade } from '@vip/state/opcao-faturamento';
import { CupomFacade } from '@vip/state/cupom';
import {
  AntifraudeEnum,
  FormaPagamentoEnum,
  HeaderUtilsService,
  ICompraPagamento,
  ICupomCredito,
  IFormaPagamento,
  IPortador,
  ISelectOptions,
  ITipoEntrega,
  LayoutUtilsService,
  TipoClienteEnum,
  TipoDispositivoEnum,
  TituloPagamentoEnum,
  environment,
} from '@vip/core';
import { ClienteFacade } from '@vip/state/cliente';
import { NavigationEnd, Router } from '@angular/router';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DescontoFacade } from '@vip/state/desconto';
import { FidelidadeFacade } from '@vip/state/fidelidade';
import { PagamentoService } from '../services/pagamentos.service';
import { CashbackFacade } from '@vip/state/cashback';
import {
  TipoEntregaFacade,
  ValidaEntregaService,
} from '@vip/state/tipo-entrega';
import { EnderecoFacade } from '@vip/state/endereco';
import { DialogService, IDialog, ModalService } from '@vip/ui/modal';
import { IFinalizarCompraPayload, IPayloadCompraPagamento } from '@vip/ws';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  from,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
import { CartaoFacade } from '@vip/state/cartao';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { ParametrosFacade } from '@vip/state/parametros';
import {
  ModalCaptchaComponent,
  PagamentoCartaoDirective,
} from '@vip/views/pagamento';
import { AnalyticsFacade } from '@vip/state/analytics';
import { BucketsFacade } from '@vip/state/buckets';
import { FilialFacade } from '@vip/state/filial';
import { LayoutStore } from '@vip/state/layout';
import { PagamentoComponent } from '@vip/views/pagamento';
import { ConfirmacaoSaidaComponent } from '@vip/core';
import { PerguntasFacade } from '@vip/state/perguntas';
import { ModalPerguntasComponent } from '@vip/ui/modal-perguntas';
import { IonRouterOutlet } from '@ionic/angular';
import { BaseApiResponse, LojaApiService } from '@vip/api';
import { PagamentoDesktopComponent } from '@vip/views/pagamento-desktop';
import { UntypedFormGroup } from '@angular/forms';
import { ContainerModalErrorComponent } from '@vip/container/modal-error';
import * as TipoEntregaActions from '@vip/state/tipo-entrega';
import { Actions, ofType } from '@ngrx/effects';
import { TabComponent } from '@vip/ui/tabs';

@UntilDestroy()
@Component({
  selector: 'vip-pagamento-container',
  templateUrl: './pagamento-container.component.html',
  styles: [
    `
      :host {
        @apply h-full;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PagamentoContainerComponent
  extends PagamentoCartaoDirective
  implements OnInit, ConfirmacaoSaidaComponent, AfterViewInit
{
  @ViewChild(PagamentoComponent)
  pagamentoComponent!: PagamentoComponent;
  @ViewChild(PagamentoDesktopComponent)
  pagamentoDesktopComponent!: PagamentoDesktopComponent;

  @ViewChild('fpSessionId', { static: false }) fpSessionIdInput!: ElementRef;
  @ViewChild('fpAppId', { static: false }) fpAppIdInput!: ElementRef;
  tipoClienteEnum = TipoClienteEnum;
  private portador: IPortador | undefined;
  private latestSetedPortador?: IPortador;
  formaPagamentoSelecionada$ =
    this.formaPagamentosFacade.formaPagamentosSelecionadas$;
  cuponsSelecionados$ = this.cupomFacade.cuponsSelecionados$;
  formaFaturamento$ = this.opcaoFaturamentoFacade.formaFaturamento$;
  opcaoFaturamentoSelecionada$ =
    this.opcaoFaturamentoFacade.opcaoFaturamentoSelecionada$;
  cliente$ = this.clienteFacade.cliente$;
  compraEmProcesso$ = this.compraEmProcessoFacade.compraEmProcesso$;
  exibeCashback$ = this.cashbackFacade.exibeCashback$;
  saldoCashback$ = this.cashbackFacade.saldoCashback$;
  produtosDesconto$ = this.fidelidadeFacade.produtosDescontoFidelidade$;
  codigoPromocional$ = this.descontoFacade.codigoPromocional$;
  loading$ = this.compraEmProcessoFacade.loading$;
  tipoEntregaSelecionado$ = this.tipoEntregaFacade.tipoEntregaSelecionado$;
  pagamentos$: Observable<ICompraPagamento[]>;
  cartaoSelecionadoId$ = this.cartaoFacade.cartaoSelecionadoId$;
  valorTroco$ = this.formaPagamentosFacade.valorTroco$;
  cdSelecionado$ = this.centroDistribuicaoFacade.cd$;
  isLojaAutonoma$ = this.centroDistribuicaoFacade.isLojaAutonoma$;
  taxaServico$ = this.compraEmProcessoFacade.taxaServico$;
  valorMinimoCashback$ = this.cashbackFacade.valorMinimo$;
  formaPagamentos$ = this.formaPagamentosFacade.formaPagamentos$;
  cartoes$ = this.cartaoFacade.cartoes$;
  removerPrePagos$ = this.formaPagamentosFacade.removerPrePagos$;
  permitirSalvarCartao$ = this.filialFacade.permitirSalvarCartao$;
  isDesktop = this.layoutUtilsService.isDesktop();
  filialId = 0;
  filial$ = this.filialFacade.filial$.pipe(
    tap((filial) => {
      this.filialId = filial.id;
    }),
  );
  logo$ = this.headerUtilsService.getLogo(
    this.bucketsFacade.bucketS3$,
    this.filial$,
  );
  logoFallback$ = this.headerUtilsService.getLogoFallback(
    this.bucketsFacade.bucketS3$,
  );
  pagamentosEntrega$ = this.formaPagamentosFacade.pagamentosEntrega$;
  pagamentosOnline$ = this.formaPagamentosFacade.pagamentosOnline$;
  loadedPagamentos$ = this.formaPagamentosFacade.isLoaded$;
  exibeModalBandeiraNaoAtendida$ =
    this.formaPagamentosFacade.bandeiraNaoAtendidaDispatched$;
  valorFinalCompra = 0;
  pressBack = false;
  disablePagamentoButton = true;
  taxaEntrega$ = this.compraEmProcesso$.pipe(
    map((pedido) => pedido?.frete || 0),
  );

  private facebookFormaPgIdSubject = new Subject<number>();
  facebookFormaPgId$ = this.facebookFormaPgIdSubject.asObservable();

  private facebookParcelaSubject = new Subject<ISelectOptions>();
  facebookParcela$ = this.facebookParcelaSubject.asObservable();

  private loadingSubject = new BehaviorSubject<boolean>(true);
  loadingTipoEntrega$ = this.loadingSubject.asObservable();

  excedeuLimiteTentativas$ = combineLatest([
    this.formaPagamentoSelecionada$,
    this.parametrosFacade.limiteTentativasPrePago$,
    this.parametrosFacade.tentativasRealizadas$,
  ]).pipe(
    map(([formaPagamento, limiteTentativas, tentativasRealizadas]) => {
      if (
        formaPagamento !== null &&
        formaPagamento.pagamento !== FormaPagamentoEnum.PRE_PAGO
      )
        return false;

      if (
        limiteTentativas &&
        tentativasRealizadas &&
        tentativasRealizadas > Number(limiteTentativas)
      )
        return true;

      return false;
    }),
  );
  formasPagamentosPrePagasCartaoLoja$ =
    this.formaPagamentosFacade.pagamentos$.pipe(
      map((pagamento) => {
        const formaPagamento = pagamento?.online.cartao_da_loja || null;
        if (formaPagamento && formaPagamento.length > 0) {
          this.formaPagamentosOptionsCartaoLoja = [];
          formaPagamento.forEach((pagamento) =>
            this.addFormaPagamentoOptions(
              pagamento,
              this.formaPagamentosOptionsCartaoLoja,
            ),
          );
        }
        return formaPagamento;
      }),
    );
  formasPagamentosPrePagasCartaoCredito$ =
    this.formaPagamentosFacade.pagamentos$.pipe(
      map((pagamento) => {
        const formaPagamento = pagamento?.online.credito || null;
        if (formaPagamento && formaPagamento.length > 0) {
          this.formaPagamentosOptionsCartaoCredito =
            pagamento?.online.credito.map((pagamento) => {
              return {
                value: pagamento.id.toString(),
                text: pagamento.descricao,
              };
            }) || [];
        }
        return formaPagamento;
      }),
    );

  formasPagamentosPrePagasVoucher$ =
    this.formaPagamentosFacade.pagamentos$.pipe(
      map((pagamento) => {
        const formaPagamento = pagamento?.online.voucher;
        if (formaPagamento && formaPagamento.length > 0) {
          this.formasPagamentosOptionsVoucher =
            pagamento?.online.voucher.map((pagamento) => {
              return {
                value: pagamento.id.toString(),
                text: pagamento.descricao,
              };
            }) || [];
        }
        return formaPagamento;
      }),
    );
  formasPagamentosCarteiraDigital$ =
    this.formaPagamentosFacade.pagamentos$.pipe(
      map((pagamento) => {
        const formaPagamento = pagamento?.online.carteira_digital;
        if (formaPagamento && formaPagamento.length > 0) {
          this.formasPagamentosOptionsCarteiraDigital =
            pagamento?.online.carteira_digital.map((pagamento) => {
              return {
                value: pagamento.id.toString(),
                text: pagamento.descricao,
              };
            }) || [];
        }
        return formaPagamento;
      }),
    );
  permiteSelecaoManual$ = this.formaPagamentosFacade.permiteSelecaoManual$;
  bandeiraNaoAtendida$ = this.formaPagamentosFacade.bandeiraNaoAtendida$;
  cupons$ = this.cupomFacade.meusCupons$;
  temPerguntaObrigatoria$ =
    this.perguntasFacade.temPerguntaObrigatoriaSemResposta$;
  perguntasDoPagamento$ = this.perguntasFacade.perguntasDoPagamento$;
  permitirBandeiraAutomatica$ = this.filialFacade.permitirBandeiraAutomatica$;
  isLoadingCompraEmProcesso$ = this.compraEmProcessoFacade.loading$;
  isLoadingCartao$ = this.cartaoFacade.loading$;
  validationInProgress = false;
  compraEmProcessoError$ = this.compraEmProcessoFacade.error$;
  public antifraudesAtivos: Record<string, boolean> = {};
  subscriptionVerifyForm?: Subscription;
  isDesktopResponsive = this.layoutUtilsService.isDesktopResponsive();
  isTelevendas$ = this.televendasFacade.isTelevendas;
  isLoading = false;
  validandoPrazo = false;
  validaPagamentoMobile = false;
  confirmaModal = false;
  confirmouPagamento = false;
  getTiposEntregasEnderecoSuccess$ = this.actions$.pipe(
    ofType(TipoEntregaActions.getTiposEntregasEnderecoSuccess),
  );

  loadingSubject$ = new BehaviorSubject<boolean>(true);
  private isModalErrorOpen = false;
  private isModalPrazoOpen = false;
  private isModalCaptchaOpen = false;
  private isModalPerguntasOpen = false;
  private concluirAposValidar = signal(false);
  currentActiveTab = signal<TabComponent | undefined>(undefined);

  constructor(
    @Inject(IS_APP) private isApp: boolean,
    private clienteFacade: ClienteFacade,
    private filialFacade: FilialFacade,
    private cupomFacade: CupomFacade,
    private enderecoFacade: EnderecoFacade,
    private formaPagamentosFacade: FormaPagamentosFacade,
    private opcaoFaturamentoFacade: OpcaoFaturamentoFacade,
    private router: Router,
    public compraEmProcessoFacade: CompraEmProcessoFacade,
    private fidelidadeFacade: FidelidadeFacade,
    private cashbackFacade: CashbackFacade,
    private tipoEntregaFacade: TipoEntregaFacade,
    private validaEntregaService: ValidaEntregaService,
    public descontoFacade: DescontoFacade,
    public pagamentoService: PagamentoService,
    dialogService: DialogService,
    public cartaoFacade: CartaoFacade,
    private modalTrocoService: ModalTrocoService,
    private centroDistribuicaoFacade: CentroDistribuicaoFacade,
    private parametrosFacade: ParametrosFacade,
    private modalService: ModalService<
      | ModalCaptchaComponent
      | ModalPerguntasComponent
      | ValidarPrazoEntregaContainerComponent
      | ContainerModalErrorComponent
    >,
    private analyticsFacade: AnalyticsFacade,
    private layoutUtilsService: LayoutUtilsService,
    private layoutStore: LayoutStore,
    private bucketsFacade: BucketsFacade,
    private perguntasFacade: PerguntasFacade,
    private lojaApiService: LojaApiService,
    private _elementRef: ElementRef,
    @Optional() private readonly routerOutlet: IonRouterOutlet,
    private headerUtilsService: HeaderUtilsService,
    private televendasFacade: TelevendasFacade,
    private televendasTokenService: TelevendasTokenService,
    private carrinhoFacade: CarrinhoFacade,
    private facebookPixelService: FacebookPixelService,
    private actions$: Actions,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private pagamentoUtilsService: PagamentoUtilsService,
  ) {
    super(dialogService);

    this.pagamentoService.compraEmProcessoValorFinal$
      .pipe(untilDestroyed(this))
      .subscribe((compraEmProcessoValorFinal) => {
        this.valorFinalCompra = compraEmProcessoValorFinal;
      });

    combineLatest([
      this.formaPagamentoSelecionada$,
      this.pagamentoService.compraEmProcessoValorFinalSemAcrescimo$,
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([formaPgto, compraEmProcessoValorFinalSemAcrescimo]) => {
        if (!this.validationInProgress) this.disablePagamentoButton = true;
        if (this.valorFinalCompra <= 0) {
          this.disablePagamentoButton = false;
        } else if (formaPgto) {
          if (this.isDesktop) this.enablePagamentoButtonByPaymentMethod();
          else {
            this.disablePagamentoButton = false;
          }
          this.pagamentoService.updateParcelasOptions(
            formaPgto,
            compraEmProcessoValorFinalSemAcrescimo,
          );
        }
      });

    if (!this.isApp && !this.isDesktopResponsive) {
      combineLatest([this.compraEmProcesso$, this.formaPagamentoSelecionada$])
        .pipe(untilDestroyed(this))
        .subscribe(([compraEmProcesso, formaPagamentoSelecionada]) => {
          if (compraEmProcesso && !formaPagamentoSelecionada) {
            this.analyticsFacade.addEntregaInfo(compraEmProcesso);
          }
          if (compraEmProcesso && formaPagamentoSelecionada) {
            this.analyticsFacade.addPagamentoInfo(
              formaPagamentoSelecionada.id,
              compraEmProcesso,
            );
          }
        });
    }

    this.pagamentos$ = combineLatest([
      this.compraEmProcesso$,
      this.formaPagamentoSelecionada$,
      pagamentoService.compraEmProcessoValorFinal$,
    ]).pipe(
      map(([compraEmProcesso, formaPagamentoSelecionada, valorFinal]) => {
        if (compraEmProcesso && !formaPagamentoSelecionada) {
          this.analyticsFacade.addEntregaInfo(compraEmProcesso);
        }
        if (valorFinal == 0 || !formaPagamentoSelecionada) return [];
        return [
          {
            valor: valorFinal,
            juros: 0,
            parcelas:
              formaPagamentoSelecionada.pagamento ==
                FormaPagamentoEnum.PRE_PAGO && compraEmProcesso?.portador
                ? compraEmProcesso?.portador.installments
                : 1,
            cc_number:
              formaPagamentoSelecionada.pagamento ==
                FormaPagamentoEnum.PRE_PAGO && compraEmProcesso?.portador
                ? compraEmProcesso?.portador.cc_number
                : null,
            forma_pagamento: {
              ...formaPagamentoSelecionada,
              logo:
                formaPagamentoSelecionada.logomarca ||
                '../assets/images/default_image_md.png',
            },
            carteira_digital: formaPagamentoSelecionada.carteira_digital,
          },
        ];
      }),
    );

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(function (event): event is NavigationEnd {
          return event instanceof NavigationEnd && event.url === '/pagamento';
        }),
      )
      .subscribe(() => {
        this.validaEntregaService.startTimer();
        this.verificarAntifraudesAtivos();
      });

    this.getTiposEntregasEnderecoSuccess$
      .pipe(untilDestroyed(this), take(1))
      .subscribe((response: any) => {
        this.loadingSubject.next(!response.tiposEntregas);
      });

    if (this.validaEntregaService.desktopTrigger$) {
      this.validaEntregaService.desktopTrigger$
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.confirmaModal = false;
          if (!this.isModalErrorOpen && !this.isModalPrazoOpen) {
            if (this.isModalCaptchaOpen || this.isModalPerguntasOpen) {
              this.fecharModaisEspecificos();
            } else {
              this.tipoEntregaFacade.getTiposEntregasEndereco();
              this.abrirModalValidarPrazoEntrega();
            }
          }
        });
    }
  }

  @HostListener('window:popstate', ['$event'])
  canDeactivate(): Observable<boolean> {
    return this.compraEmProcesso$.pipe(
      delay(1),
      switchMap((compraEmProcesso) => {
        if (
          !compraEmProcesso ||
          this.isDesktop ||
          !this.pagamentoComponent.pressBack
        )
          return of(true);
        return from(
          this.pagamentoService.exibirModalAvisoVoltar(
            compraEmProcesso.id,
            compraEmProcesso.filial_id,
            compraEmProcesso.centro_distribuicao_id,
          ),
        );
      }),
    );
  }

  private addFormaPagamentoOptions(
    formaPag: IFormaPagamento,
    options: ISelectOptions[],
  ) {
    options.push({
      value: formaPag.id.toString(),
      text: formaPag.descricao,
    });
  }

  validateCompraNoPrazo(
    validarDataCompra: boolean,
    first = false,
  ): Promise<boolean> {
    this.validandoPrazo = true;
    return new Promise((resolve, reject) => {
      combineLatest([
        this.compraEmProcessoFacade.compraEmProcesso$.pipe(take(1)),
        this.carrinhoFacade.carrinho$.pipe(take(1)),
        this.clienteFacade.cliente$.pipe(take(1)),
        this.isLojaAutonoma$.pipe(take(1)),
      ]).subscribe(([compraEmProcesso, carrinho, cliente, isLojaAutonoma]) => {
        this.validaEntregaService
          .validarEntregaNoPrazo(
            compraEmProcesso,
            carrinho,
            cliente,
            validarDataCompra,
            this.confirmaModal,
          )
          .then((response) => {
            this.validandoPrazo = false;
            if (response === false && !isLojaAutonoma) {
              resolve(false);
              if (this.isDesktop) {
                return this.abrirModalValidarPrazoEntrega();
              } else {
                if (this.validaPagamentoMobile) {
                  return this.validaEntregaService.showAppDialog();
                }
              }
            } else {
              resolve(true);
              this.isLoading = false;
              this.resetAllFormaPagamento();
            }
          })
          .catch((e) => {
            this.validandoPrazo = false;
            this.isLoading = false;
            reject(false);
          });
      });
    });
  }

  private resetAllFormaPagamento() {
    this.resetPermiteSelecaoManual();
    this.formaPagamentosFacade.resetBandeiraNaoAtendidaCartao();
    this.formaPagamentosFacade.resetPermiteSelecaoManual();
  }

  ngOnInit(): void {
    this.isLoading = false;
    if (this.layoutUtilsService.isDesktop())
      this.formaPagamentosFacade.resetFormaPagamentoSelecionada();
    this.validateCompraNoPrazo(true);

    if (this.layoutUtilsService.isMobile()) {
      this.toggleSwipeGestureNavigation();
    }
    this.opcaoFaturamentoFacade.getOpcaoFaturamento();
    this.enderecoFacade.getEnderecos();
    this.validaEntregaService.validaEntrega();
    this.validaEntregaService.startTimer();
    this.layoutStore.setHeaderVisibility(false);
    this.buscarCashback();
    this.buscaTaxaServico();
    if (this.isDesktop) {
      this.formaPagamentosFacade.getFormaPagamentos();
      this.cartaoFacade.getCartoes();
      this.cupomFacade.getCuponsDisponiveis();
      this.perguntasFacade.getPerguntas();
    }
    this.pagamentoService.confirmarVoltar$
      .pipe(untilDestroyed(this))
      .subscribe(({ compraId, filialId, cdId }) => {
        this.continuarComprando(compraId, filialId, cdId);
      });
    this.formaPagamentosFacade.buscarBandeiraDispatched$
      .pipe(
        untilDestroyed(this),
        filter(({ permiteSelecaoManual }) => permiteSelecaoManual),
      )
      .subscribe(() => {
        this.selecionaFormaPagamento({
          formaPagamentoId: null,
          originFromEvent: this.currentActiveTab()?.id,
        });
      });

    this.formaPagamentosFacade.formaPagamentosSelecionadas$
      .pipe(
        untilDestroyed(this),
        (formaPagamentoSelecionada) =>
          combineLatest([formaPagamentoSelecionada, this.facebookParcela$]),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      )
      .subscribe(([formaPagamentoSelecionado, parcela]) => {
        this.facebookPixelService.addPaymentInfo(
          formaPagamentoSelecionado,
          parcela.value,
        );
      });

    this.tipoEntregaFacade.error$.subscribe((error) => {
      if (error !== null && !this.isModalErrorOpen) {
        this.validaErroModalTipoEntrega();
      }
    });
    this.restaureFlagCameFrom();
  }

  validaErroModalTipoEntrega() {
    this.modalService.clearModal().then(() => {
      setTimeout(() => {
        this.abrirModalError();
      }, 500);
    });
  }

  ngAfterViewInit(): void {
    this.tipoEntregaFacade.getTipoEntregaSuccess$
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.validaEntregaService.validaEntrega();
      });
  }
  removerCupom(cupom: ICupomCredito) {
    this.cupomFacade.removerCupomSelecionado(cupom);
  }

  onChangeOpcaoFaturamento(selecionada: string) {
    this.opcaoFaturamentoFacade.selectOpcaoFaturamento(selecionada);
  }

  buscaTaxaServico() {
    this.compraEmProcessoFacade.getTaxaServico();
  }

  confirmaPagamentoMobile() {
    this.isLoading = true;
    if (!this.isDesktop) {
      this.validaPagamentoMobile = true;
    }
    this.validateCompraNoPrazo(false, true).then((result) => {
      this.ngZone.run(() => {
        this.confirmouPagamento = true;
        if (!result) {
          this.isLoading = false;
          this.cdr.detectChanges();
          return;
        }
        this.isLoading = false;
        this.cdr.detectChanges();
        this.confirmarPagamento();
      });
    });
  }

  confirmarPagamento() {
    this.confirmouPagamento = true;
    this.compraEmProcessoFacade.compraEmProcesso$
      .pipe(
        untilDestroyed(this),
        take(1),
        withLatestFrom(
          this.opcaoFaturamentoFacade.opcaoFaturamentoSelecionada$,
          this.formaPagamentoSelecionada$,
          this.cupomFacade.cuponsSelecionados$,
          this.descontoFacade.codigoPromocional$,
          this.tipoEntregaFacade.tipoEntregaEHorarioSelecionados$.pipe(
            map(([tipoEntregaSelecionado, horarioSelecionado]) => {
              return { tipoEntregaSelecionado, horarioSelecionado };
            }),
          ),
          this.valorTroco$,
          this.taxaServico$,
        ),
        map(
          ([
            compraEmProcesso,
            opcaoFaturamentoSelecionada,
            formaPagamentoSelecionada,
            cupons,
            codigoPromocional,
            tipoEntregaEHorarioSelecionados,
            valorTroco,
            taxaServico,
          ]) => ({
            compraEmProcesso,
            opcaoFaturamentoSelecionada,
            formaPagamentoSelecionada,
            cupons,
            codigoPromocional,
            tipoEntregaEHorarioSelecionados,
            valorTroco,
            taxaServico,
          }),
        ),
      )
      .subscribe(
        ({
          compraEmProcesso,
          opcaoFaturamentoSelecionada,
          formaPagamentoSelecionada,
          cupons,
          codigoPromocional,
          tipoEntregaEHorarioSelecionados,
          valorTroco,
          taxaServico,
        }) => {
          if (
            !formaPagamentoSelecionada &&
            this.valorFinalCompra !== 0 &&
            !this.isDesktop
          ) {
            this.mostraMensagemEscolhaFormapagamento();
          } else if (
            formaPagamentoSelecionada &&
            formaPagamentoSelecionada.id === 1 &&
            valorTroco < this.valorFinalCompra &&
            !this.isDesktop
          ) {
            this.pedirTroco();
          } else {
            if (
              compraEmProcesso &&
              opcaoFaturamentoSelecionada &&
              tipoEntregaEHorarioSelecionados.tipoEntregaSelecionado
            ) {
              const payload: IFinalizarCompraPayload = {
                id: compraEmProcesso.id,
                tipo_fiscal: opcaoFaturamentoSelecionada,
                compra_pagamentos: [],
                portador: compraEmProcesso.portador ?? this.portador,
                entrega: {
                  ...tipoEntregaEHorarioSelecionados.tipoEntregaSelecionado,
                  horarios_formatados: [
                    tipoEntregaEHorarioSelecionados.horarioSelecionado,
                  ],
                  data_entrega:
                    tipoEntregaEHorarioSelecionados.horarioSelecionado?.key ||
                    tipoEntregaEHorarioSelecionados.tipoEntregaSelecionado
                      .data_entrega,
                  intervalo:
                    tipoEntregaEHorarioSelecionados.horarioSelecionado
                      ?.intervalo ||
                    tipoEntregaEHorarioSelecionados.tipoEntregaSelecionado
                      .intervalo,
                },
                cupons: cupons,
                codigo: codigoPromocional ? codigoPromocional.codigo : null,
                televendas: this.televendasTokenService.getToken(),
                valor_cashback: compraEmProcesso.valor_cashback,
                loja_autonoma: !!compraEmProcesso.loja_autonoma,
                tipo_dispositivo: this.isDesktop
                  ? TipoDispositivoEnum.SITE
                  : TipoDispositivoEnum.APLICATIVO,
                taxa_de_servico: taxaServico?.valor_taxa,
                antifraude_session_id: this.antifraudeSessionId,
              };

              if (formaPagamentoSelecionada && this.valorFinalCompra) {
                if (
                  formaPagamentoSelecionada.pagamento ===
                    FormaPagamentoEnum.POS_PAGO ||
                  !!payload.televendas
                ) {
                  payload.compra_pagamentos.push(
                    this.getCompraPagamentosPosPago(
                      formaPagamentoSelecionada,
                      valorTroco,
                    ),
                  );
                } else if (formaPagamentoSelecionada.carteira_digital) {
                  payload.compra_pagamentos.push(
                    this.getCompraPagamentosCarteiraDigital(
                      formaPagamentoSelecionada,
                    ),
                  );
                } else {
                  if (compraEmProcesso.portador) {
                    payload.compra_pagamentos.push(
                      this.getCompraPagamentosCartão(
                        formaPagamentoSelecionada,
                        compraEmProcesso.portador,
                      ),
                    );
                  } else if (this.isDesktop && this.portador) {
                    payload.compra_pagamentos.push(
                      this.getCompraPagamentosCartão(
                        formaPagamentoSelecionada,
                        this.portador,
                      ),
                    );
                  }
                }
              }

              if (environment.showCaptcha) {
                this.abrirModalCaptcha(compraEmProcesso.filial_id, payload);
              } else {
                this.compraEmProcessoFacade.finalizarCompra(
                  compraEmProcesso.filial_id,
                  payload,
                );
              }

              if (codigoPromocional.descontoFrete) {
                payload.codigo_desconto = {
                  ...codigoPromocional.descontosCodigoPromocional[0],
                  tipo_codigo: 'FRETE',
                };
              }
            }
          }
        },
      );
  }

  abrirModalCaptcha(filialId: number, payload: IFinalizarCompraPayload) {
    this.isModalCaptchaOpen = true;
    const modalRef = this.modalService.openModal(ModalCaptchaComponent, {
      bottom: true,
    });

    if (modalRef) {
      const componentModalCaptcha = modalRef.instance as ModalCaptchaComponent;
      componentModalCaptcha.captchaChange
        .pipe(untilDestroyed(this))
        .subscribe((token: string) => {
          this.isModalCaptchaOpen = false;
          payload.captchaToken = token;
          this.compraEmProcessoFacade.finalizarCompra(filialId, payload);
        });
    }
  }

  utilizarValorCashback(valorSelecionado: number) {
    this.compraEmProcessoFacade.setCashback(valorSelecionado);
  }

  setValorTrocoPagamento(valorTroco: number) {
    this.formaPagamentosFacade.setValorTroco(valorTroco);
  }

  voltarContinuarComprandoDesktop() {
    this.compraEmProcesso$
      .pipe(take(1), untilDestroyed(this))
      .subscribe((compra) => {
        if (compra) {
          this.pagamentoService.exibirModalAvisoVoltar(
            compra?.id,
            compra.filial_id,
            compra.centro_distribuicao_id,
          );
        }
      });
  }

  continuarComprando(compraId: number, filialId: number, cdId: number): void {
    this.compraEmProcessoFacade.recriarCarrinhoContinuarComprando(
      compraId,
      filialId,
      cdId,
    );
    this.tipoEntregaFacade.resetTiposEntregaSelecionada();
  }

  concluirCompraDesktop(): void {
    this.isLoading = true;
    this.validandoPrazo = true;
    if (!this.isDesktop) {
      this.validaPagamentoMobile = true;
    }
    this.validateCompraNoPrazo(true, true).then((result) => {
      this.confirmouPagamento = true;

      if (!result) {
        this.isLoading = false;
        this.concluirAposValidar.set(true);
        return;
      }

      this.isLoading = false;
      this.cdr.detectChanges();
      combineLatest([this.temPerguntaObrigatoria$, this.perguntasDoPagamento$])
        .pipe(untilDestroyed(this), take(1))
        .subscribe(([temPerguntaObrigatoria, perguntasDoPagamento]) => {
          if (perguntasDoPagamento.length > 0) {
            this.isModalPerguntasOpen = true;
            const modalRef = this.modalService.openModal(
              ModalPerguntasComponent,
            ) as ComponentRef<ModalPerguntasComponent>;
            modalRef.instance.temPerguntaObrigatoria = temPerguntaObrigatoria;
            modalRef.instance.perguntas = perguntasDoPagamento;
            modalRef.instance.salvarPerguntas
              .pipe(untilDestroyed(this))
              .subscribe((respostas) => {
                this.isModalPerguntasOpen = false;
                const respostasValues = Object.values(respostas);
                const naoTemResposta = respostasValues.every(
                  (resposta) => resposta === null,
                );
                if (naoTemResposta) {
                  this.perguntasFacade.pularPerguntas(true);
                } else {
                  this.perguntasFacade.responderPerguntas(respostas);
                }
                this.modalService.clearModal();
                setTimeout(() => {
                  this.confirmarPagamento();
                }, 400);
              });
            modalRef.instance.pularPerguntas
              .pipe(untilDestroyed(this))
              .subscribe(() => {
                this.perguntasFacade.pularPerguntas(true);
                this.modalService.clearModal();
                setTimeout(() => {
                  this.confirmarPagamento();
                }, 400);
              });
          } else {
            this.confirmarPagamento();
          }
        });
    });
  }
  selectParcelaFn(parcelaSelecionada: ISelectOptions) {
    if (parcelaSelecionada?.info) {
      this.pagamentoService.setValorAcrescimos(parcelaSelecionada.info);
      this.compraEmProcessoFacade.updateParcelas(
        Number(parcelaSelecionada.value),
      );
      this.facebookParcelaSubject.next(parcelaSelecionada);
      if (this.portador)
        this.portador = {
          ...this.portador,
          installments: Number(parcelaSelecionada.value),
        };
    }
  }

  selecionaFormaPagamento(event: {
    formaPagamentoId: string | number | null;
    originFromEvent: string | number | undefined;
  }) {
    const { formaPagamentoId, originFromEvent } = event;
    if (this.currentActiveTab()?.id !== originFromEvent) return;
    this.portador = undefined;
    if (formaPagamentoId) {
      this.facebookFormaPgIdSubject.next(Number(formaPagamentoId));
      this.formaPagamentosFacade.selectFormaPagamento(Number(formaPagamentoId));
    } else {
      this.formaPagamentosFacade.resetFormaPagamentoSelecionada();
    }
  }

  deletarCartaoFn(cartaoId: string): void {
    this.cartaoFacade.deletarCartaoSemDialog(cartaoId);
  }

  onChangeCuponsSelecionados(event: {
    cupons: ICupomCredito[];
    adicionado: boolean;
  }) {
    this.cupomFacade.setCuponsSelecionados(event.cupons);
  }

  buscaBandeiraCartao(
    event: {
      cardNumber: string;
      makeRequestAgain?: boolean;
      tipoPagamento: 'credito' | 'voucher';
    },
    dontChangeStateStatus?: boolean,
  ) {
    this.formaPagamentosFacade.buscarBandeiraCartao(
      event.cardNumber,
      event.tipoPagamento,
      event.makeRequestAgain,
      dontChangeStateStatus,
    );
  }

  handleTabChange(): void {
    this.resetPermiteSelecaoManual();
    this.formaPagamentosFacade.cancelarRequestBuscarBandeiraCartao();
  }

  private buscarCashback(): void {
    this.cashbackFacade.getSaldoCashback();
  }

  private mostraMensagemEscolhaFormapagamento() {
    this.isLoading = false;
    const dialogData: IDialog = {
      open: true,
      title: 'Você não selecionou a forma de pagamento!',
      subTitle:
        'Para finalizar a compra, você precisa escolher um método de pagamento.',
      buttonConfirmText: 'Escolher Forma de Pagamento',
      disabled: false,
    };
    this.dialogService?.openDialog(dialogData);
    this.dialogService?.dialogClick.pipe(untilDestroyed(this)).subscribe(() => {
      this.isLoading = false;
      this.cdr.detectChanges();
      this.router.navigateByUrl('pagamento/forma-pagamento');
      this.dialogService?.clearDialog();
    });
  }

  private verificaTemValorDaCompra() {
    return this.valorFinalCompra <= 0;
  }

  private setValidar(formaPagamentoSelecionada: IFormaPagamento) {
    return formaPagamentoSelecionada.pagamento ===
      FormaPagamentoEnum.PRE_PAGO && !this.verificaTemValorDaCompra()
      ? 1
      : 0;
  }

  private pedirTroco() {
    this.modalTrocoService.selecionarTroco(this.valorFinalCompra);
    this.modalTrocoService.valorTrocoEventEmitter
      .pipe(untilDestroyed(this))
      .subscribe((valorTroco) => {
        this.setValorTrocoPagamento(valorTroco);
      });
  }

  private getCompraPagamentosPosPago(
    formaPagamentoSelecionada: IFormaPagamento,
    valorTroco?: number,
  ) {
    const result: IPayloadCompraPagamento = {
      valor: this.valorFinalCompra,
      valor_declarado: formaPagamentoSelecionada.id == 1 ? valorTroco : 0,
      confirmado: this.verificaTemValorDaCompra(),
      parcelas: 1,
      forma_pagamento_id: formaPagamentoSelecionada.id,
      carteira_digital: false,
      debito: !(formaPagamentoSelecionada.id == 1),
    };
    if (this.televendasTokenService.getToken()) result['adicionado'] = true;
    return result;
  }

  private getCompraPagamentosCarteiraDigital(
    formaPagamentoSelecionada: IFormaPagamento,
  ) {
    const result: IPayloadCompraPagamento = {
      valor: this.valorFinalCompra,
      confirmado: this.verificaTemValorDaCompra(),
      parcelas: 1,
      forma_pagamento_id: formaPagamentoSelecionada.id,
      carteira_digital: true,
      validar: this.setValidar(formaPagamentoSelecionada),
      online: true,
      bandeira: formaPagamentoSelecionada.bandeira,
      descricao: formaPagamentoSelecionada.descricao,
      debito: false,
    };
    if (this.televendasTokenService.getToken()) result['adicionado'] = true;
    return result;
  }

  private getCompraPagamentosCartão(
    formaPagamentoSelecionada: IFormaPagamento,
    portador: IPortador,
  ) {
    const result: IPayloadCompraPagamento = {
      valor: this.valorFinalCompra,
      adicionado: this.verificaTemValorDaCompra(),
      confirmado: this.verificaTemValorDaCompra(),
      parcelas: portador.installments || 1,
      forma_pagamento_id: formaPagamentoSelecionada.id,
      carteira_digital: false,
      validar: this.setValidar(formaPagamentoSelecionada),
      online: true,
      bandeira: formaPagamentoSelecionada.bandeira,
      descricao: formaPagamentoSelecionada.descricao,
      debito: false,
      bin: portador.cc_number,
      cpf_portador: portador.cpf_portador,
      nome_portador: portador.cc_holder,
      intervalo_pagamento: formaPagamentoSelecionada.intervalo_pagamento,
      validade_cartao: `${portador.cc_expire_month}/${portador.cc_expire_year}`,
      desativado: false,
    };
    if (this.televendasTokenService.getToken()) result['adicionado'] = true;
    return result;
  }

  private toggleSwipeGestureNavigation(): void {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe((event) => {
        const { url } = event;
        if (url === '/pagamento') {
          this.routerOutlet.swipeGesture = false;
        } else {
          this.routerOutlet.swipeGesture = true;
        }
      });
  }

  setCVV(value: string) {
    this.compraEmProcessoFacade.updateCvv(value);

    if (this.portador) this.portador = { ...this.portador, cc_cvv: value };
  }

  setPortador(portador: IPortador | undefined) {
    this.portador = portador;
    this.latestSetedPortador = portador;
    this.compraEmProcessoFacade.setPortador(this.portador);
  }

  get antifraudeSessionId() {
    if (!this.fpSessionIdInput || !this.fpSessionIdInput.nativeElement) {
      return undefined;
    }

    if (this.antifraudesAtivos[AntifraudeEnum.CLEARSALE]) {
      return this.fpSessionIdInput.nativeElement.value;
    }

    return undefined;
  }

  private verificarAntifraudesAtivos() {
    forkJoin([
      this.lojaApiService.getAntifraudeFlag(AntifraudeEnum.CLEARSALE),
      this.lojaApiService.getAntifraudeFlag(AntifraudeEnum.EMAILAGE),
    ])
      .pipe(
        untilDestroyed(this),
        tap((antifraudeAtivo) => {
          this.antifraudesAtivos[AntifraudeEnum.CLEARSALE] =
            antifraudeAtivo[0].success && antifraudeAtivo[0].data;

          this.antifraudesAtivos[AntifraudeEnum.EMAILAGE] =
            antifraudeAtivo[1].success && antifraudeAtivo[1].data;
        }),
        filter(() => this.antifraudesAtivos[AntifraudeEnum.CLEARSALE]),
        switchMap(() => this.lojaApiService.getAppId(AntifraudeEnum.CLEARSALE)),
      )
      .subscribe((appId) => {
        this.appendClearsaleFingerPrintScript(appId);
      });
  }

  private appendClearsaleFingerPrintScript(appId: BaseApiResponse<string>) {
    this.fpAppIdInput.nativeElement.value = appId.success ? appId.data : null;

    const script = document.createElement('script');
    script.type = 'application/javascript';
    script.src = '/assets/js/clearsale-fingerprint.js';
    this._elementRef.nativeElement.appendChild(script);
  }

  resetPermiteSelecaoManual(): void {
    this.formaPagamentosFacade.resetPermiteSelecaoManual();
  }

  private enablePagamentoButtonByPaymentMethod(): void {
    let selectedTab;
    let creditoOpcaoPagamentoComponent;
    let formsCartao;
    let formCartaoSalvo;
    let cadastrandoNovoCartao;

    try {
      selectedTab =
        this.pagamentoDesktopComponent.opcoesPagamentoComponent.selectedTab;
      creditoOpcaoPagamentoComponent =
        this.pagamentoDesktopComponent.opcoesPagamentoComponent
          .creditoOpcaoPagamentoComponent;
      formsCartao =
        this.pagamentoDesktopComponent.opcoesPagamentoComponent.formsCartao;
      formCartaoSalvo =
        this.pagamentoDesktopComponent.opcoesPagamentoComponent.formCartaoSalvo;
      cadastrandoNovoCartao =
        creditoOpcaoPagamentoComponent?.cadastrandoNovoCartao || false;
    } catch (e) {
      setTimeout(() => {
        this.enablePagamentoButtonByPaymentMethod();
      }, 200);
      return;
    }

    switch (selectedTab()?.id) {
      case TituloPagamentoEnum.CREDITO:
        this.validationInProgress = true;
        if (cadastrandoNovoCartao && formsCartao) {
          creditoOpcaoPagamentoComponent.resetarFormaPgto
            .pipe(untilDestroyed(this))
            .subscribe(() => this.resetPermiteSelecaoManual());
          this.subscribeFormValueChange(
            formsCartao[TituloPagamentoEnum.CREDITO],
          );
        } else if (formCartaoSalvo) {
          this.subscribeFormValueChange(formCartaoSalvo, true);
          this.disablePagamentoButton = false;
        }
        break;

      case TituloPagamentoEnum.CARTAO_DA_LOJA:
        if (formsCartao) {
          this.validationInProgress = true;
          this.subscribeFormValueChange(
            formsCartao[TituloPagamentoEnum.CARTAO_DA_LOJA],
          );
        }
        break;
      case TituloPagamentoEnum.VOUCHER:
        if (formsCartao) {
          this.validationInProgress = true;
          this.subscribeFormValueChange(
            formsCartao[TituloPagamentoEnum.VOUCHER],
          );
        }
        break;
      default:
        if (this.subscriptionVerifyForm)
          this.subscriptionVerifyForm.unsubscribe();
        this.validationInProgress = false;
        this.disablePagamentoButton = false;
        break;
    }
  }

  private subscribeFormValueChange(
    form: UntypedFormGroup,
    isFormCartaoSalvo = false,
  ): void {
    if (this.subscriptionVerifyForm) this.subscriptionVerifyForm.unsubscribe();
    this.subscriptionVerifyForm = form?.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
        ),
      )
      .subscribe((value) => {
        const isValid = form.status === 'VALID';
        if (!this.validandoPrazo) {
          this.disablePagamentoButton = !isValid;
          if (isValid) {
            this.setPortador(
              isFormCartaoSalvo
                ? { ...this.latestSetedPortador, ...value }
                : value,
            );
          }
        }
      });
  }

  abrirModalValidarPrazoEntrega() {
    const modalPrazo = this.modalService.openModal(
      ValidarPrazoEntregaContainerComponent,
      {
        bottom: false,
        hasPrazoEntrega: true,
        large: true,
        showCloseButton: true,
        disabled: false,
        applySidePaddings: false,
      },
    );

    this.isModalPrazoOpen = true;
    this.isLoading = false;
    this.tipoEntregaFacade.resetTiposEntregaSelecionada();

    if (modalPrazo) {
      const instance = modalPrazo.instance;

      if (instance instanceof ValidarPrazoEntregaContainerComponent) {
        instance.loading$ = this.loadingTipoEntrega$;
        instance.confirm.pipe(take(1)).subscribe(() => {
          this.modalService.clearModal();
          this.confirmaModal = true;
          if (this.concluirAposValidar()) {
            this.concluirCompraDesktop();
            this.concluirAposValidar.set(false);
          } else {
            this.resetAllFormaPagamento();
          }
        });
      }

      if (this.modalService.closeClick?.asObservable) {
        this.modalService.closeClick
          .asObservable()
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this.resetAllFormaPagamento();
            this.isModalPrazoOpen = false;
          });
      }
    }
  }

  abrirModalError() {
    if (this.isModalErrorOpen) return;

    this.isModalErrorOpen = true;

    const modalError = this.modalService.openModal(
      ContainerModalErrorComponent,
      {
        bottom: false,
        hasPrazoEntrega: true,
        large: false,
        showCloseButton: true,
        disabled: false,
      },
    );

    if (modalError) {
      const instance = modalError.instance;

      if (instance instanceof ContainerModalErrorComponent) {
        instance.supportCheck
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            const url = '/institucional/fale-conosco';
            this.router.navigateByUrl(url, { replaceUrl: true });
            this.modalService.clearModal();
            this.isModalErrorOpen = false;
          });
      }

      this.validandoPrazo = true;

      if (this.modalService.closeClick?.asObservable) {
        this.modalService.closeClick
          .asObservable()
          .pipe(take(1), untilDestroyed(this))
          .subscribe(() => {
            this.modalService.clearModal();
            window.location.reload();
            this.isModalErrorOpen = false;
          });
      }

      this.modalService.closeClick.pipe(untilDestroyed(this)).subscribe(() => {
        window.location.reload();
        this.isModalErrorOpen = false;
      });
    }
  }

  private fecharModaisEspecificos() {
    this.modalService.clearModal().then(() => {
      setTimeout(() => {
        this.confirmaModal = false;
        this.tipoEntregaFacade.getTiposEntregasEndereco();
        this.abrirModalValidarPrazoEntrega();
      }, 500);
    });
  }

  private restaureFlagCameFrom(): void {
    setTimeout(() => {
      this.pagamentoUtilsService.cameFromEntregaRetirada.set(false);
      this.pagamentoUtilsService.cameFromFormaPagamento.set(false);
    }, 1000);
  }
}
