import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  EffectRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  output,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  IPagamento,
  ITipoPagamento,
  TituloPagamentoEnum,
  IFormaPagamento,
  ICartao,
  ICupomCredito,
  ICodigoPromocional,
  LayoutUtilsService,
} from '@vip/core';
import { TabComponent, TabGroupDesktopComponent } from '@vip/ui/tabs';
import { PagamentoCartaoDirective } from '@vip/views/pagamento';
import { CreditoOpcaoPagamentoComponent } from '../credito-opcao-pagamento/credito-opcao-pagamento.component';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { DialogService } from '@vip/ui/modal';

@UntilDestroy()
@Component({
  selector: 'vip-opcoes-pagamento',
  templateUrl: './opcoes-pagamento.component.html',
  styleUrls: ['./opcoes-pagamento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class OpcoesPagamentoComponent
  extends PagamentoCartaoDirective
  implements AfterViewInit, OnChanges, OnDestroy
{
  private _pagamentosOnline?: IPagamento;
  private _pagamentosEntrega?: IPagamento;
  private _removerPrepagos = false;
  private _totalCompra = 0;
  public troco = 0;
  readonly CHANGE_TAB_ANIMATION_TIME = 500;
  readonly INDEX_PAGAMENTO_ENTREGA = 1;
  readonly INDEX_PAGAMENTO_ONLINE = 0;
  form: UntypedFormGroup;
  readonly TITULO_PAGAMENTO_ENUM = TituloPagamentoEnum;
  private tipoCartaoBandeiraAutomatica!: 'credito' | 'voucher';
  private timeoutID!: number;
  private carteiraDigitalSelecionada = false;

  validations = {
    required: 'Por favor, informe um valor.',
    min: 'Valor do troco tem que ser maior que o da compra.',
  };

  tiposPagamentoOnline: ITipoPagamento[] = [];
  tiposPagamentoEntrega: ITipoPagamento[] = [];
  selectedTab = signal<TabComponent | undefined>(undefined);
  selectedTabEffectRef?: EffectRef;
  selectedTabChange = output<TabComponent | undefined>();

  @ViewChild(TabGroupDesktopComponent)
  tabGroup!: TabGroupDesktopComponent;
  @ViewChild(CreditoOpcaoPagamentoComponent)
  creditoOpcaoPagamentoComponent!: CreditoOpcaoPagamentoComponent;
  formsCartao?: Record<string, UntypedFormGroup>;
  formCartaoSalvo?: UntypedFormGroup;
  initialIndex?: number;
  private fromCarteiraDigital = false;

  @Input()
  set pagamentosOnline(value: IPagamento | undefined) {
    this._pagamentosOnline = value;
    if (value) this.tiposPagamentoOnline = value.tipos;
    this.verificarOpcoesPagamentosOnline();
  }
  get pagamentosOnline(): IPagamento | undefined {
    return this._pagamentosOnline;
  }

  @Input()
  set removerPrepagos(value: boolean) {
    this._removerPrepagos = value;

    if (value && this.tabGroup) {
      this.tiposPagamentoOnline = [];
      this.tabGroup.initTabGroup();
      this.selectedTab.set(
        this.tabGroup._allTabs.get(this.tabGroup.selectedIndex),
      );
      this.changeDetector.detectChanges();
    }
  }

  get removerPrepagos(): boolean {
    return this._removerPrepagos;
  }

  @Input()
  set pagamentosEntrega(value: IPagamento | undefined) {
    this._pagamentosEntrega = value;
    if (value?.tipos.length) this.tiposPagamentoEntrega = value.tipos;
  }

  get pagamentosEntrega(): IPagamento | undefined {
    return this._pagamentosEntrega;
  }

  @Input()
  cartoes?: ICartao[];
  @Input()
  cartaoSelecionadoId?: string | null = null;
  @Input()
  cupons: ICupomCredito[] = [];
  @Input()
  cuponsSelecionados: ICupomCredito[] = [];
  @Input()
  codigoPromocional: ICodigoPromocional | null = null;
  @Input()
  valorDescontos = 0;
  @Input()
  loadedPagamentos = false;
  @Input()
  isTelevendas = false;

  @Input()
  set totalCompra(value: number) {
    this._totalCompra = value;
    this.form.controls['troco'].setValidators(Validators.min(value));
  }

  get totalCompra(): number {
    return this._totalCompra;
  }

  get semValorPendente(): boolean {
    return this.totalCompra <= 0;
  }

  @Output()
  ativarCodigo = new EventEmitter<string>();
  @Output()
  deleteCodigoPromocionalClick = new EventEmitter<string>();

  @Output()
  buscarBandeiraCartao = new EventEmitter();

  @Output() setValorTroco = new EventEmitter<number>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    public layoutUtilsService: LayoutUtilsService,
    private activatedRoute: ActivatedRoute,
    dialogService: DialogService,
  ) {
    super(dialogService);
    this.form = this.formBuilder.group({
      troco: ['', [Validators.required]],
    });

    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe((param) => {
        if (param['isFrom'] === 'carteira-digital') {
          this.fromCarteiraDigital = true;
        }
      });

    this.selectedTabEffectRef = effect(() => {
      this.selectedTabChange.emit(this.selectedTab());
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      'loadedPagamentos' in changes &&
      changes['loadedPagamentos'].currentValue
    ) {
      if (!this.fromCarteiraDigital) this.verificarOpcoesPagamentosOnline();
    }
  }

  ngAfterViewInit(): void {
    if (this.removerPrepagos) {
      this.tiposPagamentoOnline = [];
      this.tabGroup.initTabGroup();
      this.selectedTab.set(
        this.tabGroup._allTabs.get(this.tabGroup.selectedIndex),
      );
      this.changeDetector.detectChanges();
    }

    this.setTabFormaDePagamentoSelecionada();

    this.verificarOpcoesPagamentosOnline(true);
    this.initListenerBandeiraNaoAtendida();
  }

  ngOnDestroy(): void {
    this.selectedTabEffectRef?.destroy();
  }

  getPagamentosEntrega() {
    const tiposPagamento = this.isTelevendas
      ? [...this.tiposPagamentoOnline, ...this.tiposPagamentoEntrega]
      : this.tiposPagamentoEntrega;
    return new Array<IFormaPagamento>().concat(
      ...tiposPagamento.map((tipo) => tipo.disponiveis),
    );
  }

  verificarOpcoesPagamentosOnline(useTimer?: boolean) {
    const timeToWait = useTimer ? this.CHANGE_TAB_ANIMATION_TIME + 350 : 0;
    if (this.loadedPagamentos) {
      setTimeout(() => {
        const { selectedIndex } = this.tabGroup;
        const tabIndex =
          this.formaPagamentoSelecionada && selectedIndex
            ? selectedIndex
            : this.tiposPagamentoOnline.length < 1
              ? this.INDEX_PAGAMENTO_ENTREGA
              : this.INDEX_PAGAMENTO_ONLINE;
        this.tabGroup._handleClick(tabIndex);
        this.setTipoCartaoBandeiraAutomatica(this.tabGroup.selectedIndex);
        this.changeDetector.detectChanges();
        this.tabGroup.initTabGroup();
        this.selectedTab.set(
          this.tabGroup._allTabs.get(this.tabGroup.selectedIndex),
        );
      }, timeToWait);
      if (this.tiposPagamentoOnline.length > 1)
        this.setTipoCartaoBandeiraAutomatica(this.INDEX_PAGAMENTO_ONLINE);
    }
  }

  setTipoCartaoBandeiraAutomatica(index: number) {
    this.tipoCartaoBandeiraAutomatica =
      this.tiposPagamentoOnline[index]?.titulo === 'voucher'
        ? 'voucher'
        : 'credito';
  }

  handleBuscarBandeiraCartao(event: {
    cardNumber: string;
    makeRequestAgain?: boolean;
  }): void {
    this.buscarBandeiraCartao.emit({
      ...event,
      tipoPagamento: this.tipoCartaoBandeiraAutomatica,
    });
  }

  handleAlterarBandeiraCartao(bandeira: string, originFromEvent: string): void {
    if (
      this.carteiraDigitalSelecionada ||
      this.isPagamentoEntregaSelected() ||
      this.cartaoSelecionadoId
    )
      return;
    this.selectFormaPagamentoWithOrigin.emit({
      formaPagamentoId: bandeira,
      originFromEvent,
    });
  }

  private isPagamentoEntregaSelected(): boolean {
    if (!this.tiposPagamentoOnline.length) return true;
    const { selectedIndex } = this.tabGroup;
    const { length: pagamentosOnlineLength } = this.tiposPagamentoOnline;

    return selectedIndex === pagamentosOnlineLength;
  }

  setTroco(event: Event) {
    event.preventDefault();
    const { value } = this.form.controls['troco'];

    if (this.timeoutID) clearTimeout(this.timeoutID);

    if (value > this.totalCompra) {
      this.timeoutID = window.setTimeout(
        () => this.setValorTroco.emit(value),
        500,
      );
    }
  }

  carteiraDigitalChange($event: number, originFromEvent: string) {
    this.carteiraDigitalSelecionada = !!$event;
    this.formaPagamentoChangeWithOrigin.emit({
      formaPagamentoId: String($event),
      originFromEvent,
    });
  }

  handleTabChange(index: number): void {
    this.formaPagamentoChange.emit();
    this.tabChange.emit();
    this.setTipoCartaoBandeiraAutomatica(index);
    this.selectedTab.set(
      this.tabGroup._allTabs.get(this.tabGroup.selectedIndex),
    );
    this.carteiraDigitalSelecionada = false;
  }

  addFormsToList(form: UntypedFormGroup, key: string): void {
    if (!this.formsCartao) {
      this.formsCartao = {
        [key]: form,
      };
    } else {
      this.formsCartao[key] = form;
    }
  }

  setTabFormaDePagamentoSelecionada() {
    if (this.formaPagamentoSelecionada) {
      if (
        this.tiposPagamentoOnline.some(
          (pagamento) =>
            pagamento.titulo === this.formaPagamentoSelecionada?.tipo_pagamento,
        )
      ) {
        this.initialIndex = this.tiposPagamentoOnline.findIndex(
          (pagamento) =>
            pagamento.titulo === this.formaPagamentoSelecionada?.tipo_pagamento,
        );
      }
      if (
        this.tiposPagamentoEntrega.some(
          (pagamento) =>
            pagamento.titulo === this.formaPagamentoSelecionada?.tipo_pagamento,
        )
      ) {
        this.initialIndex = this.INDEX_PAGAMENTO_ENTREGA;
      }

      setTimeout(() => {
        if (!!this.initialIndex && this.initialIndex >= 0) {
          this.tabGroup._handleClick(this.initialIndex, true);
        }
      }, 500);
    }
  }
}
