import {
  AfterViewChecked,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BandeiraEnum,
  environment,
  ICompra,
  ICompraDesconto,
  ICompraPagamento,
  ITaxaServico,
  ShareService,
} from '@vip/core';
import { CountdownBarComponent } from '@vip/ui/countdown-bar';
import { IMessage, MessageService } from '@vip/ui/message';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { ValidaEntregaService } from '@vip/state/tipo-entrega';
import { Router, NavigationStart } from '@angular/router';

@UntilDestroy()
@Directive()
export class PagamentoCarteiraDigitalBaseDirective
  implements AfterViewChecked, OnDestroy, OnInit
{
  ePix = false;
  tempoRestante = 0;
  tempoLimitePagamento = environment.toggleNovaShipay ? 600 : 300;
  subscriptionCountdownBar$?: Subscription;
  subscriptionCountdownBarFinish$?: Subscription;
  private _compraPagamento!: ICompraPagamento | null;
  get compraPagamento(): ICompraPagamento | null {
    return this._compraPagamento;
  }
  set compraPagamento(value: ICompraPagamento | null) {
    this._compraPagamento = value;
    if (value) {
      this.definirSeEPix(value);
      this.setTempoRestante();

      if (this.ePix) {
        this.validaEntregaService.stopTimer();
      }
    }
  }
  private _compraEmProcesso!: ICompra | null;
  get compraEmProcesso() {
    return this._compraEmProcesso;
  }
  @Input()
  set compraEmProcesso(value: ICompra | null) {
    this._compraEmProcesso = value;
    if (value) {
      this.compraPagamento =
        this._compraEmProcesso &&
        this._compraEmProcesso.compra_pagamentos.length
          ? this._compraEmProcesso.compra_pagamentos[
              this._compraEmProcesso.compra_pagamentos.length - 1
            ]
          : null;
    }
  }
  @Input()
  descontos?: ICompraDesconto[];
  @Input() taxaServico: ITaxaServico | null = null;
  @Input() valorDescontos?: number;
  @Input() tempoEsgotado = false;
  @Input() loading = false;
  @Input() currentDate: string | null = null;

  @Output()
  clickedCopy = new EventEmitter();

  @Output()
  clickedDeeplink = new EventEmitter();

  @Output()
  clickedCancelarPagamento = new EventEmitter();

  @Output()
  clickedTentarNovamente = new EventEmitter();

  @Output()
  tempoExpirado = new EventEmitter();

  @Output()
  backButtonClicked = new EventEmitter();

  @Output()
  timerIsRunning = new EventEmitter<boolean>();

  @ViewChild(CountdownBarComponent)
  countdownBar?: CountdownBarComponent;
  private handleWindowFocusBound = this.handleWindowFocus.bind(this);

  constructor(
    private messageService: MessageService,
    private shareService: ShareService,
    private validaEntregaService: ValidaEntregaService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    window.addEventListener('focus', this.handleWindowFocusBound);

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.removeHandleWindowFocus();
        this.unsubscribeTodos();
      }
    });
  }

  @HostListener('unloaded')
  ngOnDestroy(): void {
    this.removeHandleWindowFocus();
    this.unsubscribeTodos();
  }

  ngAfterViewChecked(): void {
    if (!this.subscriptionCountdownBarFinish$ && this.countdownBar) {
      this.subscriptionCountdownBarFinish$ = this.countdownBar.finished
        .pipe(untilDestroyed(this), take(1))
        .subscribe(() => this.handleTempoEsgotado());
    }

    if (!this.subscriptionCountdownBar$ && this.countdownBar) {
      this.subscriptionCountdownBar$ = this.countdownBar.decreased
        .pipe(untilDestroyed(this), take(1))
        .subscribe(() => this.timerIsRunning.emit(true));
    }
  }

  definirSeEPix(compraPagamento: ICompraPagamento): void {
    this.ePix =
      compraPagamento.forma_pagamento.bandeira === BandeiraEnum.PIX ||
      compraPagamento.forma_pagamento.bandeira === 'shipay-pagador';
  }

  setTempoRestante(): void {
    let tempoRestante = 0;

    if (this.tempoEsgotado) {
      this.tempoRestante = 0;
    }

    if (this.compraPagamento?.created) {
      const tempoCriacao = new Date(this.compraPagamento?.created);
      const tempoLimite = addSeconds(tempoCriacao, 590);
      const now = this.compraEmProcesso?.current_date
        ? new Date(this.compraEmProcesso?.current_date)
        : new Date();
      tempoRestante = differenceInSeconds(tempoLimite, now);
    }
    this.tempoRestante = tempoRestante;

    if (this.tempoRestante > 0) {
      this.tempoEsgotado = false;
    }
  }

  copiarCodigo() {
    this.shareService.shareLinkOnDesktop(
      this.compraPagamento?.qr_code_text as string,
    );
    const messageData: IMessage = {
      icon: 'icon-check',
      position: 'top',
      type: 'success',
    };
    this.messageService.openMessage(messageData, 'Código copiado!', 0.5);
    this.clickedCopy.emit(this.compraPagamento?.qr_code_text);
  }

  handleTempoEsgotado() {
    this.removeHandleWindowFocus();
    this.unsubscribeTodos();
    this.tempoEsgotado = true;
    this.tempoRestante = 0;
    this.tempoExpirado.emit(this.compraPagamento);
  }

  private handleWindowFocus(): void {
    this.setTempoRestante();
    if (this.tempoRestante <= 0 && !this.tempoEsgotado) {
      this.handleTempoEsgotado();
    }
  }

  private removeHandleWindowFocus(): void {
    window.removeEventListener('focus', this.handleWindowFocusBound);
  }

  private unsubscribeTodos() {
    if (this.subscriptionCountdownBar$) {
      this.subscriptionCountdownBar$.unsubscribe();
      this.subscriptionCountdownBar$ = undefined;
    }

    if (this.subscriptionCountdownBarFinish$) {
      this.subscriptionCountdownBarFinish$.unsubscribe();
      this.subscriptionCountdownBarFinish$ = undefined;
    }
  }
}
