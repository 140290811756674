import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IHorariosFormatados, ITipoEntrega } from '@vip/core';
import { CentroDistribuicaoFacade } from '@vip/state/centro-distribuicao';
import { EntregaRetiradaPropertiesBaseDirective } from '@vip/views/entrega-retirada';
@Component({
  selector: 'vip-prazo-entrega',
  templateUrl: './validar-prazo-entrega.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ValidarPrazoEntregaComponent
  extends EntregaRetiradaPropertiesBaseDirective
  implements AfterViewInit
{
  @Input() tiposEntrega: ITipoEntrega[] = [];
  @Input() hasValidaPrazo?: boolean = false;
  @Input() tipoEntregaSelecionadoPrazo: ITipoEntrega | null = null;
  @Input() loadingTipoEntrega: boolean | null = false;
  @Input() hasTipoRetiradaPrazoEntrega: boolean | null = false;
  @Input() tipoEntrega: boolean | undefined = false;
  @Output() entregaRetirada = new EventEmitter();
  @Output() horarioRetirada = new EventEmitter();
  @Output() confirm = new EventEmitter();
  @Input() isEntrega = false;
  @Input() loadingTeste = false;
  @Input() desativarConfirmar = false;
  @Input() hasErroOpen = false;
  @Input() resetHorarioSelected = false;
  private initialized = false;
  horarioSelecionadoString = '';

  horarioRetiradaSelected: IHorariosFormatados | null = null;
  hasError = false;
  messageError = [
    {
      id: 0,
      title: `Você precisa selecionar o tipo de ${
        this.tipoEntrega ? 'entrega' : 'retirada'
      } que deseja receber sua compra`,
    },
    {
      id: 1,
      title:
        'Você precisa escolher o dia e horário que deseja receber a sua compra para continuar',
    },
  ];

  constructor(cdFacade: CentroDistribuicaoFacade) {
    super(cdFacade);
    this.tipoEntregaSelecionado = null;
  }

  ngAfterViewInit(): void {
    this.initialized = true;
  }

  getTiposEntregaOrdenados(tiposEntrega: ITipoEntrega[]) {
    return tiposEntrega.sort((a, b) => {
      if (a.alocar === b.alocar) {
        return a.descricao > b.descricao ? 1 : -1;
      } else {
        return Number(b.alocar) - Number(a.alocar);
      }
    });
  }

  changeSelectTipoEntrega(event: ITipoEntrega) {
    this.tipoEntregaSelecionadoPrazo = event;
    this.tipoEntregaSelecionado = event;
    this.entregaRetirada.emit(event);
    this.hasError = false;
    this.horarioSelecionadoString = '';
    this.horarioRetiradaSelected = null;
  }

  changeHorarioTipoEntrega(event: IHorariosFormatados) {
    if (event || event === null) {
      this.hasError = false;
      this.horarioRetirada.emit(event);
      this.horarioRetiradaSelected = event;
    }
  }

  checkConfirmButtonState() {
    if (this.tipoEntregaSelecionadoPrazo === null) {
      this.hasError = true;
    } else if (this.horarioRetiradaSelected === null) {
      this.hasError = true;
    } else {
      this.hasError = false;
      this.confirm.emit();
    }
  }
}
