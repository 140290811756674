<div class="flex flex-col h-full w-full relative">
  <div class="flex flex-col flex-grow" *ngIf="compraPagamento && !loading">
    <div>
      <div class="carteira-digital-content rounded block flex flex-col">
        <div class="mx-7 mt-4 text-base vip-color-secondary-default">
          <ng-container *ngIf="ePix; else tituloNaoPix">
            <div *ngIf="!tempoEsgotado">
              Por favor, leia o qr-code abaixo com o seu celular para realizar o
              pagamento através do aplicativo da carteira digital. O pedido será
              concluído assim que recebermos a confirmação do pagamento.
            </div>
          </ng-container>
          <ng-template #tituloNaoPix>
            Realize o pagamento através do aplicativo da carteira digital. O
            pedido será concluído assim que recebermos a confirmação.
          </ng-template>
        </div>

        <div *ngIf="!tempoEsgotado" class="text-center font-bold mt-10 mb-3">
          Aguardando pagamento
        </div>

        <div
          class="rounded shadow self-center max-w-[20.625rem] p-3 mb-4"
          *ngIf="!ePix; else qrCodePix"
        >
          <ng-container *ngIf="!tempoEsgotado; else divtempoEsgotado">
            <img
              data-cy="qr-code-carteira-digital"
              class="qr-code mx-auto mt-3"
              [src]="compraPagamento.qr_code"
            />
            <vip-button
              *ngIf="!ePix"
              type="raised"
              class="w-fit mt-4 m-auto flex fixed-width"
              (btnClick)="clickedDeeplink.emit(compraPagamento.deep_link)"
            >
              Ir para o site
            </vip-button>
          </ng-container>
        </div>
        <ng-template #qrCodePix>
          <ng-container *ngIf="!tempoEsgotado; else divtempoEsgotado">
            <img
              data-cy="qr-code-pix"
              class="qr-code mx-auto my-10 mt-3 mb-4"
              [src]="compraPagamento.qr_code"
            />
          </ng-container>
        </ng-template>
        <div
          *ngIf="!tempoEsgotado"
          class="min-w-[20.5rem] self-center mb-10 text-base"
        >
          <vip-countdown-bar
            class="min-w-[20.5rem] self-center text-base"
            [timeTotal]="tempoLimitePagamento"
            [timeLeft]="tempoRestante"
            (finished)="handleTempoEsgotado(); timerIsRunning.emit(false)"
          ></vip-countdown-bar>
        </div>
        <div class="flex justify-end gap-4">
          <vip-button
            type="raised"
            class="flex"
            (btnClick)="
              tempoEsgotado
                ? clickedTentarNovamente.emit(compraEmProcesso)
                : clickedCancelarPagamento.emit(compraPagamento)
            "
          >
            Alterar Forma de Pagamento
          </vip-button>
          <vip-button
            *ngIf="!tempoEsgotado"
            type="raised"
            class="flex"
            (btnClick)="copiarCodigo()"
            data-cy="button-copy"
          >
            <i class="icon-copy mr-1"></i>
            Copiar código
          </vip-button>
        </div>
      </div>

      <ng-template #divtempoEsgotado>
        <div
          class="flex flex-col text-sm gap-3 max-w-[20.5rem] justify-center self-center text-center vip-color-secondary-default border rounded p-2 mb-2"
        >
          <span class="mb-2"
            >O tempo para confirmação do pagamento se esgotou.</span
          >
          <span class="mb-2"
            >O QR Code e a solicitação de pagamento serão cancelados. Se algum
            valor já tiver sido pago, ele será estornado automaticamente.</span
          >
          <span>Tente novamente ou altere a forma de pagamento.</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>
