import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { CompraEmProcessoFacade } from '@vip/state/compra-em-processo';
import { DescontoFacade } from '@vip/state/desconto';
import { PagamentoService } from '../services/pagamentos.service';
import {
  ICompra,
  ICompraPagamento,
  LayoutUtilsService,
  StepStatusEnum,
} from '@vip/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { interval, Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { FilialFacade } from '@vip/state/filial';
import { BucketsFacade } from '@vip/state/buckets';
import { LayoutStore } from '@vip/state/layout';
import { TipoEntregaFacade } from '@vip/state/tipo-entrega';
import { FormaPagamentosFacade } from '@vip/state/forma-pagamento';

@UntilDestroy()
@Component({
  selector: 'vip-pagamento-carteira-digital-container',
  templateUrl: './pagamento-carteira-digital-container.component.html',
  styleUrls: ['./pagamento-carteira-digital-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class PagamentoCarteiraDigitalContainerComponent implements OnInit {
  compraEmProcesso$ = this.compraEmProcessoFacade.compraEmProcesso$;
  currentDate$ = this.compraEmProcessoFacade.currentDate$;
  loadingCompraEmProcesso$ = this.compraEmProcessoFacade.loading$;
  descontos$ = this.descontoFacade.descontos$;
  totalDesconto$ = this.descontoFacade.totalDesconto$;
  tempoExpirado = false;
  compraEmProcesso: ICompra | null = null;
  compraPagamentoDigital: ICompraPagamento | null = null;
  isMobile = this.layoutUtilsService.isMobile();
  filialId = 0;
  filial$ = this.filialFacade.filial$.pipe(
    tap((filial) => {
      this.filialId = filial.id;
    }),
  );
  logo$ = this.bucketsFacade.bucketS3$.pipe(
    map(
      (s3) =>
        `${s3}/files/tema/filial-${
          this.filialId
        }/logo-home-omni.png?${Date.now()}`,
    ),
  );
  logoFallback$ = this.bucketsFacade.bucketS3$.pipe(
    map((s3) => `${s3}/files/tema/logo-home-omni.png?${Date.now()}`),
  );
  steps = [
    { title: 'Entrega', link: '', status: StepStatusEnum.COMPLETED },
    { title: 'Pagamento', link: '', status: StepStatusEnum.ACTIVE },
    { title: 'Revisão', link: '', status: StepStatusEnum.PENDING },
  ];
  taxaEntrega$ = this.tipoEntregaFacade.tipoEntregaSelecionado$.pipe(
    filter((tipoEntregaSelecionado) => !!tipoEntregaSelecionado),
    map((tipoEntregaSelecionado) => {
      if (!tipoEntregaSelecionado) return 0;
      return tipoEntregaSelecionado.frete - tipoEntregaSelecionado.desconto;
    }),
  );
  totalCompra$ = this.pagamentoService.compraEmProcessoValorFinal$;
  compraPagamento: ICompraPagamento | null = null;
  isDesktopResponsive = this.layoutUtilsService.isDesktopWithoutScreenWidth();
  compraEmProcessoLoading$ = this.compraEmProcessoFacade.loading$.pipe(
    untilDestroyed(this),
    distinctUntilChanged(),
    delay(500),
    tap(() => (this.loadingHolder = false)),
  );
  private subscriber$?: Subscription;
  private subscribePagamentoDigitalCancelado$?: Subscription;

  readonly tempoExpiradoDialog: IDialog = {
    open: true,
    title: 'Você excedeu o tempo limite para finalizar a compra',
    subTitle:
      'Por favor, selecione novamente o tipo de entrega e a forma de pagamento',
    disabled: true,
    showCloseButton: false,
    buttonConfirmText: 'Selecionar tipo de entrega',
  };

  readonly cancelarPagamentoDialog: IDialog = {
    open: true,
    title: 'Cancelar pagamento',
    subTitle: 'Cancelar pagamento e retornar ao passo anterior?',
    disabled: false,
    buttonConfirmText: 'Sim',
    buttonCancelText: 'Agora não',
  };
  loadingHolder = true;

  constructor(
    private compraEmProcessoFacade: CompraEmProcessoFacade,
    private descontoFacade: DescontoFacade,
    public pagamentoService: PagamentoService,
    private dialogService: DialogService,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private filialFacade: FilialFacade,
    private bucketsFacade: BucketsFacade,
    private layoutStore: LayoutStore,
    private tipoEntregaFacade: TipoEntregaFacade,
    private formaPagamentosFacade: FormaPagamentosFacade,
  ) {}

  ngOnInit() {
    this.layoutStore.setHeaderVisibility(this.isMobile);
    this.compraEmProcessoFacade.getCompraEmProcesso();
    this.subscribeGetPagamentoDigital();
    this.subscribeRouteChange();
    this.subscribeEfetuouPagamentoDigital();
    this.subscribePagamentoDigitalCancelado();
    this.subscribeVerificarPagamento();

    if (this.isDesktopResponsive) this.layoutStore.setHeaderVisibility(false);
  }

  private subscribeGetPagamentoDigital() {
    this.compraEmProcesso$
      .pipe(untilDestroyed(this))
      .subscribe((compraEmProcesso) => {
        this.compraEmProcesso = compraEmProcesso || null;
        if (this.compraEmProcesso) {
          this.compraPagamentoDigital =
            this.compraEmProcesso.compra_pagamentos.find((compraPagamento) => {
              return compraPagamento.carteira_digital;
            }) || null;
          this.definirCompraPagamentos();
        }
      });
  }

  private subscribeVerificarPagamento() {
    this.subscriber$ = interval(2000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (
          this.compraEmProcesso &&
          this.compraPagamentoDigital?.id &&
          !this.tempoExpirado
        ) {
          this.compraEmProcessoFacade.verificarPagamentoDigital(
            this.compraEmProcesso.filial_id,
            this.compraEmProcesso.id,
            this.compraPagamentoDigital.id,
          );
        }
      });
  }

  private subscribeRouteChange() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.pagamentoService.timerCarteiraDigitalIsRunning$),
      )
      .subscribe(([event, timerCarteiraDigitalIsRunning]) => {
        if (event instanceof NavigationStart) {
          this.compraEmProcessoFacade.getCompraEmProcesso();
          if (!timerCarteiraDigitalIsRunning) {
            this.subscriber$?.unsubscribe();
            this.subscribePagamentoDigitalCancelado$?.unsubscribe();
          }
        }
      });
  }

  private subscribePagamentoDigitalCancelado(): void {
    this.subscribePagamentoDigitalCancelado$ =
      this.compraEmProcessoFacade.pagamentoDigitalCancelado$
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (!this.tempoExpirado) {
            this.pagamentoService.setTimerIsRunning(false);
            this.router.navigateByUrl('pagamento?isFrom=carteira-digital');
          }
        });
  }

  private subscribeEfetuouPagamentoDigital(): void {
    this.compraEmProcessoFacade.pagamentoDigitalEfetuado$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.pagamentoService.setTimerIsRunning(false));
  }

  onTempoExpirado() {
    this.tempoExpirado = true;
    this.subscriber$?.unsubscribe();
    this.subscribePagamentoDigitalCancelado$?.unsubscribe();
    this.pagamentoService.setTimerIsRunning(false);
    this.formaPagamentosFacade.resetFormaPagamentoSelecionada();
  }

  handleCancelarPagamentoDigital(compraPagamentoDigital: ICompraPagamento) {
    this.dialogService.openDialog(this.cancelarPagamentoDialog);

    this.dialogService.dialogClick
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (this.compraEmProcesso && compraPagamentoDigital?.id) {
          if (value) {
            this.pagamentoService.setTimerIsRunning(false);
            this.compraEmProcessoFacade.cancelarPagamentoDigital(
              this.compraEmProcesso.filial_id,
              this.compraEmProcesso.id,
              compraPagamentoDigital.id,
            );
            this.formaPagamentosFacade.resetFormaPagamentoSelecionada();
          }
          this.dialogService.clearDialog();
        }
      });
  }

  handleDeeplink(deeplink: string) {
    window.open(deeplink, '_system');
  }

  handleTentarNovamente() {
    this.pagamentoService.setTimerIsRunning(false);
    this.router.navigateByUrl('pagamento?isFrom=carteira-digital');
  }

  handleGoBack(compraPagamentoDigital: ICompraPagamento) {
    if (this.tempoExpirado) {
      this.router.navigateByUrl('pagamento');
    } else {
      this.handleCancelarPagamentoDigital(compraPagamentoDigital);
    }
  }

  get taxaServico() {
    return {
      valor_taxa: this.compraEmProcesso?.taxa_de_servico || null,
      mensagem: '',
    };
  }

  private definirCompraPagamentos(): void {
    if (
      !this.compraEmProcesso ||
      !this.compraEmProcesso.compra_pagamentos.length
    )
      return;

    this.compraPagamento =
      this.compraEmProcesso.compra_pagamentos[
        this.compraEmProcesso.compra_pagamentos.length - 1
      ];
  }
}
