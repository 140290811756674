<div class="flex flex-col h-full w-full absolute">
  <vip-screen-title
    title="Realizar Pagamento"
    [customActions]="true"
    [showActions]="true"
  >
    <vip-button-icon-rounded
      type="outline"
      (btnClick)="backButtonClicked.emit(compraPagamento)"
      class="z-30 pl-1"
    >
      <i class="vip-color-secondary-main icon-arrow_back"></i>
    </vip-button-icon-rounded>
  </vip-screen-title>
  <div class="flex flex-col flex-grow" *ngIf="compraPagamento && !loading">
    <div *ngIf="!tempoEsgotado && ePix">
      <div
        class="carteira-digital-content rounded block flex flex-col"
        *ngIf="!tempoEsgotado"
      >
        <div class="flex flex-col items-center font-bold">
          <div class="mx-auto mt-3 mb-1">
            Pagamento {{ compraPagamento.forma_pagamento.descricao }}
          </div>
          <div class="mx-auto vip-color-success-main">
            {{ compraPagamento.valor | currency: 'BRL' }}
          </div>
        </div>
        <img class="qr-code mx-auto my-10" [src]="compraPagamento.qr_code" />
        <vip-button
          type="raised"
          class="mx-auto flex fixed-width"
          (btnClick)="copiarCodigo()"
          data-cy="button-copy"
        >
          Copiar código PIX
        </vip-button>
        <vip-button
          type="normal"
          class="m-auto mt-2 mb-3 flex fixed-width"
          (btnClick)="clickedCancelarPagamento.emit(compraPagamento)"
        >
          Cancelar pagamento
        </vip-button>
      </div>
    </div>
    <div
      *ngIf="!tempoEsgotado"
      class="flex mx-3 mt-3 text-xs vip-color-secondary-default"
    >
      Realize o pagamento através do aplicativo da carteira digital. O pedido
      será concluído assim que recebermos a confirmação.
    </div>
    <div *ngIf="tempoEsgotado" class="mt-3">
      <div
        class="flex flex-col text-sm vip-color-secondary-default text-center mx-7 mb-4"
      >
        <span class="mb-4"
          >O tempo para confirmação do pagamento se esgotou.</span
        >
        <br />
        O QR Code e a solicitação de pagamento serão cancelados. Se algum valor
        já tiver sido pago, ele será estornado automaticamente.<br />
      </div>
      <div
        class="flex-col text-sm gap-3 vip-color-secondary-default text-center mx-7 max-w-[20.5rem]"
      >
        Tente novamente ou altere a forma de pagamento.
      </div>
    </div>
    <div *ngIf="!tempoEsgotado" class="w-full flex m-3 flex-col self-center">
      <vip-countdown-bar
        class="w-full flex flex-col self-center"
        [timeTotal]="tempoLimitePagamento"
        [timeLeft]="tempoRestante"
        (finished)="handleTempoEsgotado(); timerIsRunning.emit(false)"
      ></vip-countdown-bar>
    </div>
    <div *ngIf="!tempoEsgotado && !ePix">
      <div
        *ngIf="!tempoEsgotado"
        class="flex m-3 mt-0 text-xs vip-color-secondary-default"
      >
        O pagamento está sendo processado pela sua carteira digital. Seu pedido
        será concluído assim que recebermos a confirmação.
      </div>
      <div class="rounded m-3 p-3 vip-border">
        <div class="card-basico-header flex items-center">
          <vip-image
            class="flex mr-3 rounded shadow"
            [alt]="compraPagamento.forma_pagamento.descricao"
            [imageUrl]="compraPagamento.forma_pagamento.logo"
          ></vip-image>
          <div class="text-sm font-bold">
            {{ compraPagamento.forma_pagamento.descricao }}
          </div>
        </div>
        <img class="qr-code mx-auto mt-3" [src]="compraPagamento.qr_code" />
        <vip-button
          type="raised"
          class="w-fit mt-4 m-auto flex fixed-width"
          (btnClick)="clickedDeeplink.emit(compraPagamento.deep_link)"
        >
          Ir para o site
        </vip-button>
      </div>
    </div>
  </div>

  <div *ngIf="tempoEsgotado" class="flex w-full mb-4 text-center">
    <vip-button
      type="raised"
      class="w-full m-4 self-center"
      (btnClick)="clickedTentarNovamente.emit(compraEmProcesso)"
    >
      Alterar forma de pagamento
    </vip-button>
  </div>

  <vip-card-detalhes-cobranca
    [valorCompra]="compraEmProcesso?.valor_inicial || 0"
    [taxaEntrega]="compraEmProcesso?.frete || 0"
    [taxaServico]="taxaServico"
    [valorDescontos]="valorDescontos"
    [descontos]="descontos"
    [accordion]="true"
    *ngIf="!loading"
  >
  </vip-card-detalhes-cobranca>
</div>
