import {
  Directive,
  EventEmitter,
  Input,
  Optional,
  output,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ErrorBandeiraNaoAtendida,
  ICompra,
  IFormaPagamento,
  IPortador,
  ISelectOptions,
} from '@vip/core';
import { DialogService, IDialog } from '@vip/ui/modal';
import { SelectEvent } from '@vip/ui/select';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Directive()
export abstract class PagamentoCartaoDirective {
  @Input()
  parcelas?: ISelectOptions[] = [];
  @Input()
  parcelaSelecionada?: string;
  @Input()
  formaPagamentosOptions: ISelectOptions[] = [];
  @Input()
  formasPagamentosPrePagas: IFormaPagamento[] = [];
  @Input()
  permitirSalvarCartao!: boolean;
  @Input()
  permitirBandeiraAutomatica!: boolean;
  @Input()
  permiteSelecaoManual?: boolean;
  @Input()
  bandeiraNaoAtendida?: boolean;
  @Input()
  exibeModalBandeiraNaoAtendida$!: Observable<ErrorBandeiraNaoAtendida>;
  @Input()
  privateLabelBandeiraAutomatica = false;
  _formaPagamentoSelecionada: IFormaPagamento | null = null;
  @Input()
  set formaPagamentoSelecionada(value: IFormaPagamento | null | undefined) {
    this._formaPagamentoSelecionada = value || null;
  }
  get formaPagamentoSelecionada(): IFormaPagamento | null {
    return this._formaPagamentoSelecionada;
  }
  _compraEmProcesso: ICompra | null = null;
  @Input()
  set compraEmProcesso(value: ICompra | null | undefined) {
    this._compraEmProcesso = value || null;
  }
  get compraEmProcesso() {
    return this._compraEmProcesso;
  }
  @Input()
  isLoading = false;
  @Input()
  formasPagamentosPrePagasCartaoLoja: IFormaPagamento[] | null = [];
  @Input()
  formasPagamentosPrePagasCartaoCredito: IFormaPagamento[] | null = [];
  @Input()
  formaPagamentosOptionsCartaoLoja: ISelectOptions[] = [];
  @Input()
  formaPagamentosOptionsCartaoCredito: ISelectOptions[] = [];
  @Input()
  formasPagamentosOptionsVoucher: ISelectOptions[] = [];
  @Input()
  formasPagamentosPrePagasVoucher: IFormaPagamento[] | null = [];
  @Input()
  formasPagamentosCarteiraDigital: IFormaPagamento[] | null = [];
  @Input()
  formasPagamentosOptionsCarteiraDigital: ISelectOptions[] = [];
  @Input()
  limparForm$?: Observable<void>;
  _compraEmProcessoError = false;
  @Input()
  set compraEmProcessoError(compraEmProcessoError: boolean) {
    this._compraEmProcessoError = compraEmProcessoError || false;
  }
  get compraEmProcessoError(): boolean {
    return this._compraEmProcessoError;
  }
  @Input()
  resetarCcNumber$?: Observable<void>;

  @Output()
  selectFormaPagamento = new EventEmitter<string>();
  @Output()
  parcelaChange = new EventEmitter<SelectEvent>();
  @Output()
  selectParcela = new EventEmitter<ISelectOptions>();
  @Output()
  formaPagamentoChange = new EventEmitter();
  @Output()
  cvvValueChange = new EventEmitter<string>();
  @Output()
  deletarCartao = new EventEmitter<string>();
  @Output()
  alterarBandeiraCartao = new EventEmitter<string>();
  @Output()
  changedCuponsSelecionados = new EventEmitter();
  @Output()
  cartaoSalvoChange = new EventEmitter<string | null>();
  @Output() adicionarCartao = new EventEmitter<IPortador>();
  @Output()
  formEmitter = new EventEmitter<UntypedFormGroup>();
  @Output()
  tabChange = new EventEmitter<void>();
  selectFormaPagamentoWithOrigin = output<{
    formaPagamentoId: string | number | null;
    originFromEvent: string;
  }>();
  formaPagamentoChangeWithOrigin = output<{
    formaPagamentoId: string | number | null;
    originFromEvent: string;
  }>();

  private dialogOpened = false;
  private resetarCcNumberSubject = new Subject<void>();

  constructor(@Optional() protected dialogService?: DialogService) {
    this.resetarCcNumber$ = this.resetarCcNumberSubject.asObservable();
  }

  initListenerBandeiraNaoAtendida(): void {
    if (!this.dialogService) {
      throw new Error('inject DialogService as dependency');
    }

    this.exibeModalBandeiraNaoAtendida$
      ?.pipe(
        untilDestroyed(this),
        filter(
          ({ bandeiraNaoAtendida }) =>
            !this.dialogOpened && !!bandeiraNaoAtendida,
        ),
      )
      .subscribe(() => {
        const dataDialog: IDialog = {
          open: true,
          title: 'Bandeira não aceita!',
          subTitle: `Infelizmente não trabalhamos com essa bandeira de cartão.`,
          disabled: false,
          buttonConfirmText: 'Adicionar outro cartão',
        };

        this.dialogService?.openDialog(dataDialog);
        this.dialogOpened = true;

        this.dialogService?.dialogClick
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.resetarCcNumberSubject.next();
            this.dialogService?.clearDialog();
            this.dialogOpened = false;
            this.tabChange.emit();
          });
      });
  }
}
